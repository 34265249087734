@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.newsletter-block {
	background-color: colors.$orange;
	border-radius: 20px;

	&__wrapper {
		padding: 3rem;
	}

	&__form {
		display: grid;
		grid-template-columns: minmax(1rem, 100%);
		grid-template-areas: 'newsletter-label' 'newsletter-email' 'newsletter-submit';
		grid-gap: 2rem;
	}

	&__label {
		grid-area: newsletter-label;
		color: colors.$white;
		text-transform: uppercase;
		font-weight: 900;
		@extend %font-xl;
	}

	.newsletter-block__email {
		grid-area: newsletter-email;
		padding: 1.35rem 2.6rem;
		border-radius: 100px;
		border-bottom: none;
	}

	&__submit {
		grid-area: newsletter-submit;
		cursor: pointer;
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.newsletter-block__form {
		grid-template-columns: minmax(1rem, 17fr) minmax(1rem, 7fr);
		grid-template-areas: 'newsletter-label .' 'newsletter-email newsletter-submit';
		grid-gap: 3rem;
	}
}
